import React from "react";

function Hearbeat() {
  return (
    <div className="Hearbeat">
      <div className="pulse"></div>
    </div>
  );
}

export default Hearbeat;
